<template>
  <div id="links-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/links" class="btn btn-primary mb-5 ml-3">
      Zurück zur Übersicht
    </router-link>

    <div class="card">
      <div class="card-header">
        <h3>Neue Seite erstellen</h3>
      </div>
      <div class="card-body">
        <h4>Füge deine Seite zum Link Tree hinzu, um sie für die weiteren Tools zu nutzen.</h4>
        <p>
          Du kannst hier manuell eine Website mit Screenshot hinzufügen, um sie später für Customer Journeys und
          Audits nutzen zu können. Bitte beachte, dass Metriken wie Link Juice, Content-Bewertungen und andere
          Auswertungen, die mithilfe des Scrapers möglich sind beim manuellen Hinzufügen leider nicht gemacht werden
          können.
        </p>

        <div class="row">
          <div class="col-sm-6">
            <h5>Benenne deine neue Seite</h5>
            <p>Gib am besten den Titel der Seite ein, der auch im Browser erscheint</p>
            <input type="text" v-model="newNode.title" placeholder="Seitentitel" class="form-control">

            <h5 class="mt-3">Seiten-URL</h5>
            <input type="text" v-model="newNode.url" placeholder="https://..." class="form-control">

            <h5 class="mt-3">Seitentyp:</h5>
            <input type="text" v-model="newNode.pagetype" class="form-control">

            <h5 class="mt-3">Seiten verlinken:</h5>
            <ul>
              <li v-for="(node, index) in connectedNodes" :key="index">
                {{ node.title }}
                <i @click="disconnectNode(node.id)"
                   class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li>
                <!--<select @change="connectNode($event)" class="form-control">
                  <option value="">Seite zum verlinken auswählen...</option>
                  <option v-for="(node, index) in nodes" :value="node.id" :key="index">{{ node.title }}</option>
                </select>-->

                <NodeSelector :nodes="nodes"
                              :selected-node-url="selectedNode.url"
                              :exclude-items="['all']"
                              @selectNode="connectNode($event.id)"
                ></NodeSelector>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <h5 class="mt-3">Lade einen Screenshot hoch:</h5>
            <input type="file" accept="image/jpeg,image/png" class="form-control">

            <h5 class="mt-3">Vorschau:</h5>
            <img v-if="newNode.image.desktop" :src="getImg(newNode, 'desktop')" :alt="newNode.title + ' Screenshot'" class="img-fluid mb-3">
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button @click="save" class="btn btn-block btn-success">
          Seite hinzufügen
        </button>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import NodeSelector from "@/components/NodeSelector";

export default {
  name: 'LinksAdd',
  components: {
    NodeSelector,
  },
  data() {
    return {
      newNode: {
        id: "",
        url: "",
        depth: 0,
        title: "",
        status: 200,
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: "",
        },
        pagetype: ""
      },
      connectedNodes: [],
      connectedLinks: [],
      newLink: {
        link_id: "",
        sid: "",
        tid: "",
        weight: ""
      }
    }
  },
  computed: {
    nodes() {
      return this.project.netNodes.filter(node => !this.connectedNodes.find(n => n.id === node.id));
    },
    links() {
      return this.project.netLinks;
    }
  },
  watch: {
  },
  methods: {
    connectNode($event) {
      let targetNode = this.nodes.find(n => n.id === parseInt($event.target.value));
      $event.target.value = "";
      this.connectedNodes.push(targetNode);
      this.connectedLinks.push({
        link_id: this.generateId(),
        sid: this.newNode.id,
        tid: targetNode.id,
        weight: 1
      });
    },
    disconnectNode(id) {
      let nodeIndex = this.connectedNodes.findIndex(n => n.id === id);
      this.connectedNodes.splice(nodeIndex, 1);

      let linkIndex = this.connectedLinks.findIndex(l => l.tid === id);
      this.connectedLinks.splice(linkIndex, 1);
    },
    save() {
      this.project.netNodes.push(this.newNode);
      this.project.contentNodes.push(this.newNode);
      this.project.techNodes.push(this.newNode);
      for (let link of this.connectedLinks) {
        this.project.netLinks.push(link);
      }
      // TODO: Sync Server
      /*this.$store.dispatch('updateProjectByProp', { prop: 'jobs', data: this.project.journeys })
          .then(res => {
            console.log(res);
          });*/
      this.$router.push("/links");
    }
  },
  beforeMount() {
    this.newNode.id = this.generateId();
  },
  mounted() {
  }
}
</script>